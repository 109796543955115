import Image from "next/image";

export const DoctorCard = ({
    name,
    image,
    experience,
    speciality
  }: {
    name: string;
    image: string;
    experience: number;
    speciality: string
  }) => {
    return (
      <>
        <div className="w-64 md:w-[16rem] h-[20rem] relative rounded-lg flex flex-column justify-center items-center">
          <Image
            src={image}
            alt={`${name} - doctors`}
            fill
            style={{ objectFit: "cover", borderRadius: 8 }}
            sizes="(max-width: 768px) 40vw,
                (max-width: 1200px) 30vw,
                20vw"
            quality={50}
          />
        </div>
        <div className="p-4">
          <p className="text-black text-2xl font-bold text-center">{name}</p>
          {speciality && (<p className="text-black text-xl text-center">{`${speciality}`}</p>)}  
          {/* {experience && (<p className="text-black text-xl text-center">{`${experience} yrs of experience`}</p>)}          */}
        </div>
      </>
    );
  };
  