import React from "react";
import { motion } from "framer-motion";
import { popChildrenIn } from "@/lib/AnimationComponents/variants";
import useGetListOfAllDoctors from "@/lib/hooks/QueryHooks/useGetListOfAllDoctors";
import { DoctorCard } from "./DoctorCard";
import Section from "@/lib/Layout/Wrappers/Section";

const doctorVariant = popChildrenIn();

function Doctors() {
  const { data: listOfDoctors } = useGetListOfAllDoctors();

  return (
   <Section>
     <p className="text-center pt-20 text-6xl font-semibold">Meet our doctors</p>
      <motion.div
        variants={doctorVariant.container}
        initial={"hidden"}
        whileInView={"show"}
        className="flex flex-row gap-4 my-10 overflow-y-hidden snap-mandatory snap-x w-full whitespace-nowrap "
      >
        {listOfDoctors?.map(
          (
            {
              doctor_name,
              image,
              experience,
              speciality
            }: { doctor_name: string; image: string; experience: number,speciality: string },
            id: number
          ) => {
            return (
              <motion.div
                variants={doctorVariant.child}
                key={id}
                className="sm:snap-center "
              >
                <DoctorCard
                  experience={experience}
                  name={doctor_name}
                  image={image != null ? image : ""}
                  speciality={speciality}
                />
              </motion.div>
            );
          }
        )}
      </motion.div>
   </Section>
  );
}

export default Doctors;