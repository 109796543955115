import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const useGetListOfAllDoctors = () => {  
  return useQuery<any>(["getAllDoctors"], async () => {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_URL}/api/resource/Doctor?fields=["*"]&filters=[["enabled","=",1]]&order_by=ranking asc`,
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        withCredentials: true,
      }
    );
    let doctorList = res.data.data.filter((item: { enabled: number; }) => item.enabled === 1);
      return doctorList;
  })
};

export default useGetListOfAllDoctors;
